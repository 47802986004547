<template>
  <section class="right">
    <div
      class="panel step8"
      v-if="
        ($route.name == 'Order Cash Delivery' ||
          $route.name == 'Edit Cash Delivery' ||
          $route.name == 'Edit Standing Order') &&
        customer.credit_limit &&
        customer.cash_ordering
      "
    >
      <h2>
        Credit Limit
        <img src="/images/icons/right-col-info-icon-white.png" alt="info" />
      </h2>
      <div class="countdown" :class="creditLimit < 0 ? 'problem' : ''">&pound;{{ creditLimit.toFixed(0) }}</div>
      <p class="note">
        NOTE: The figure in the box above will adjust accordingly as the fields in the form are filled in.
      </p>
    </div>
    <div class="panel cutoff step3" v-if="countdown && customer.cash_ordering && customer.credit_limit > 0">
      <h2>
        Next cut-off
        <img src="/images/icons/right-col-info-icon-white.png" alt="info" />
      </h2>
      <div class="test" v-if="isTest">
        <p>Simulate date and time:</p>
        <flat-pickr v-model="test_date_string" class="txt full date"></flat-pickr>
        <input type="time" v-model="test_time_string" class="txt full" style="margin: 1rem 0" />
      </div>
      <div class="countdown">{{ countdown }}</div>
      <!-- <div class="countdown">{{ cutoffdate }}</div> -->
      <p class="note" v-if="$store.state.user_type == 'customer' || $store.state.user_type == 'hq-as-customer'">
        NOTE: The time in the box above indicates the countdown to the next cut-off before orders are moved to the
        following day.
      </p>
      <p class="note" v-if="$store.state.user_type == 'hq-user'">
        NOTE: The time in the box above indicates the countdown to the next cut-off before orders are moved to the
        following day. This time may be subject to local variations.
      </p>
      <p class="note" v-if="isBankHoliday">
        <a href="https://www.loomis.co.uk/contact/service-announcement" target="_blank" rel="noreferrer"
          >Click here for more information on <span>Bank Holiday</span> cut offs</a
        >
      </p>

      <!--<p class="note">
        <a href="https://www.loomis.co.uk/contact/service-announcement" target="_blank" rel="noreferrer"
          >Click here for more information on <span>Christmas &amp; New Year</span> cut offs</a
        >
      </p>-->
    </div>
    <div v-if="$route.name == 'Cash Order Management' && customer.cash_ordering" class="mobile-hide">
      <!-- <template v-if="scheduled && scheduled.length">
        <h2 id="h2ScheduledOrders">
          Scheduled Orders<img src="/images/icons/right-col-info-icon-white.png" alt="info" class="dark"
            v-tooltip="`Shows the next 6 orders scheduled for delivery`" />
        </h2>
        <div class="table-small">
          <table aria-label="Scheduled Orders">
            <thead>
              <th>DATE</th>
              <th>AMOUNT</th>
            </thead>
            <tbody>
              <tr v-for="(order, i) in scheduled" :Key="i">
                <td><span class="highlight">&gt;</span> {{ formatDeliveryDate(order.delivery_date) }}</td>
                <td>&pound;{{ order.value.toFixed(0) }}</td>
              </tr>
            </tbody>
          </table>
          <button class="btn-view" @click="$router.push('/scheduled-orders')">&gt; View details</button>
        </div>
      </template> -->
      <template v-if="history && history.length">
        <h2 id="h2OrderHistory">
          Order History<img
            src="/images/icons/right-col-info-icon-white.png"
            alt="info"
            class="dark"
            v-tooltip="`Shows the last 6 orders placed`"
          />
        </h2>
        <div class="table-small">
          <table aria-label="Order History">
            <thead>
              <th>DATE</th>
              <th>AMOUNT</th>
            </thead>
            <tbody>
              <tr v-for="(order, i) in history" :Key="i">
                <td><span class="highlight">&gt;</span> {{ formatDeliveryDate(order.delivery_date) }}</td>
                <td>&pound;{{ order.value.toFixed(0) }}</td>
              </tr>
            </tbody>
          </table>
          <button class="btn-view" @click="$router.push('/order-history')">&gt; View details</button>
        </div>
      </template>
    </div>

    <!-- <div class="ordernote" v-if="userType != 'hq-user' && $store.state.standingOrderEndingSoon">
      <h2>
        Order info
        <img src="/images/icons/right-col-info-icon-white.png" alt="info" />
      </h2>
      <p class="note">Your standing order is due for renewal shortly. To ensure uninterrupted service and a seamless
        experience, we
        kindly ask that you re-enter your required denominations at your earliest convenience.
      </p>
    </div> -->

    <!-- <div class="panel account-info"
      v-if="$route.meta.isHome && (customer.account_manager || customer.service_branch || customer.account_tel1)">
      <h2>Account info<img src="/images/icons/right-col-info-icon-white.png" alt="info" /></h2>

      <p v-if="customer.account_manager">
        <strong>Your Account Manager is:</strong><br />{{ customer.account_manager }}
      </p>
      <p v-if="customer.service_branch"><strong>You are serviced by our:</strong><br />{{ customer.service_branch }}</p>
      <p v-if="customer.account_tel1"><strong>You can call Support on:</strong><br />{{ customer.account_tel1 }}</p>
    </div> -->
    <div v-if="$route.name !== 'Cash Order Management'" class="useful step11">
      <h2>Useful Links</h2>
      <ul>
        <template v-for="link in filteredLinks">
          <li :key="link.route">
            <router-link :to="{ name: link.route }"
              ><img src="/images/icons/right-col-link-arrow.png" alt="link" />
              {{ link.label || link.route }}</router-link
            >
          </li>
        </template>
        <li>
          <a
            href="https://www.loomis.co.uk/our-solutions/myloomis-customer-portal/frequently-asked-questions"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/icons/right-col-link-arrow.png" alt="link" /> Frequently Asked Questions
          </a>
        </li>
        <li>
          <a href @click.prevent="logOut">
            <img src="/images/icons/right-col-link-arrow.png" alt="link" /> System Log Out
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import dateUtil from "date-and-time"

export default {
  props: ["creditLimit", "scheduled", "history"],
  data() {
    var d = new Date()
    return {
      countdown: "",
      cutoffdate: null,

      test_date: d,
      test_date_string: d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate(),
      test_time_string: d.getHours().toString().padStart(2, "0") + ":" + d.getMinutes().toString().padStart(2, "0")
    }
  },
  created() {
    var p = []
    p.push(this.$store.dispatch("getNonDeliveryDates"))
    p.push(this.$store.dispatch("getHolidayPeriods"))
    p.push(this.$store.dispatch("getServerTimezone"))

    Promise.all(p).then(() => {
      var browser_offset = new Date().getTimezoneOffset()

      if (browser_offset == this.$store.state.server_offset)
        this.getCutOffDateAndTime().then(date => {
          this.cutoffdate = date
          this.startCountdown()
        })
    })
  },
  computed: {
    filteredLinks() {
      var links = []

      if (!this.$route.meta.isHome)
        links.push({ route: this.$store.state.user_type == "hq-user" ? "HQ Home" : "Home", label: "Back to Home" })

      if (this.$store.state.user_type != "hq-as-customer")
        links.push({ route: "My Details", label: "View / Edit Account Info" })

      if (
        this.$store.state.user_type != "hq-user" &&
        this.customer.cash_ordering &&
        this.customer.credit_limit &&
        this.customer.credit_limit > 0
      ) {
        links.push({ route: "Order Cash Delivery", label: "Order Cash for Delivery" })
        links.push({ route: "Scheduled Orders" })
        links.push({ route: "Order History" })
      }

      if (this.$store.state.user_type != "hq-as-customer") links.push({ route: "Update Password" })

      return links
    },
    userType() {
      return this.$store.state.user_type
    },
    customer() {
      return this.$store.state.customer || this.$store.state.hq_user
    },
    cutoffhour() {
      return this.$store.state.cutoffhour
    },
    cutoffminute() {
      return this.$store.state.cutoffminute
    },
    holidaycutoffhour() {
      return this.$store.state.holidaycutoffhour
    },
    holidaycutoffminute() {
      return this.$store.state.holidaycutoffminute
    },
    isTest() {
      if (this.$route.query.simulate) return true
    },
    isBankHoliday() {
      var holidayperiods = this.$store.state.holiday_periods
      if (!holidayperiods) return false

      var YYYYMMDD = dateUtil.format(new Date(), "YYYY-MM-DD")
      return !!holidayperiods.filter(p => p.start_date < YYYYMMDD && p.end_date > YYYYMMDD).length
    }
  },
  watch: {
    test_date: function () {
      this.getCutOffDateAndTime().then(date => {
        this.cutoffdate = date
      })
    },
    test_date_string: function () {
      this.test_date = new Date(this.test_date_string + " " + this.test_time_string)
    },
    test_time_string: function () {
      this.test_date = new Date(this.test_date_string + " " + this.test_time_string)
    }
  },
  methods: {
    formatDeliveryDate(d) {
      return dateUtil.format(dateUtil.parse(d, "YYYY-MM-DD"), "DD/MM/YYYY")
    },
    logOut() {
      this.$store.dispatch("logOut")
    },
    getCutOffDateAndTime() {
      return new Promise((resolve, reject) => {
        var cutoffhour = this.cutoffhour
        var cutoffminute = this.cutoffminute
        var holidaycutoffhour = this.holidaycutoffhour
        var holidaycutoffminute = this.holidaycutoffminute

        var date = new Date()
        if (this.test_date && this.isTest) date = new Date(this.test_date)

        var YYYYMMDD = dateUtil.format(date, "YYYY-MM-DD")

        var holiday_periods = this.$store.state.holiday_periods

        var found = false
        holiday_periods.forEach(period => {
          var start = period.start_date
          var end = period.end_date
          var inHoliday = false
          var inGrace = false

          if (start == YYYYMMDD) {
            if (
              date.getHours() > holidaycutoffhour ||
              (date.getHours() == holidaycutoffhour && date.getMinutes() >= holidaycutoffminute)
            )
              inHoliday = true
            else if (
              date.getHours() > cutoffhour ||
              (date.getHours() == cutoffhour && date.getMinutes() >= cutoffminute)
            )
              inGrace = true
          } else if (start < YYYYMMDD && end > YYYYMMDD) inHoliday = true

          if (inHoliday) {
            date = new Date(end)
            date.setHours(cutoffhour)
            date.setMinutes(cutoffminute)
            date.setSeconds(0)
            date.setMilliseconds(0)
            found = true
          }
          if (inGrace) {
            date.setHours(holidaycutoffhour)
            date.setMinutes(holidaycutoffminute)
            date.setSeconds(0)
            date.setMilliseconds(0)
            found = true
          }
        })
        if (found) return resolve(date)

        if (
          date.getDay() == 6 ||
          date.getDay() == 0 ||
          date.getHours() > cutoffhour ||
          (date.getHours() == cutoffhour && date.getMinutes() >= cutoffminute)
        ) {
          date = addWorkingDays(date, 1, [])
          date.setHours(cutoffhour)
          date.setMinutes(cutoffminute)
          date.setSeconds(0)
          date.setMilliseconds(0)
          return resolve(date)
        }

        date.setHours(cutoffhour)
        date.setMinutes(cutoffminute)
        date.setSeconds(0)
        date.setMilliseconds(0)
        return resolve(date)
      })
    },
    calculateCountdown(timeout) {
      // get date and time being counted down to
      var countDownDate = this.cutoffdate
      countDownDate = countDownDate.getTime()

      // Get today's date and time
      var now = new Date().getTime()
      if (this.test_date && this.isTest) {
        this.test_date.setSeconds(this.test_date.getSeconds() + 1)
        now = new Date(this.test_date).getTime()

        this.test_date_string =
          this.test_date.getFullYear() + "-" + (this.test_date.getMonth() + 1) + "-" + this.test_date.getDate()
        this.test_time_string =
          this.test_date.getHours().toString().padStart(2, "0") +
          ":" +
          this.test_date.getMinutes().toString().padStart(2, "0")
      }

      // Find the distance between now and the count down date
      var distance = countDownDate - now

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24))
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      var seconds = Math.floor((distance % (1000 * 60)) / 1000)

      // convert to string
      this.countdown =
        days +
        ":" +
        hours.toString().padStart(2, "0") +
        ":" +
        minutes.toString().padStart(2, "0") +
        ":" +
        seconds.toString().padStart(2, "0")

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(timeout)

        this.getCutOffDateAndTime().then(date => {
          this.cutoffdate = date
          this.startCountdown()
        })
      }
    },
    startCountdown() {
      if (this.cutoffdate) {
        this.calculateCountdown()
        var timeout = setInterval(() => {
          this.calculateCountdown(timeout)
        }, 1000)
      }
    }
  }
}

function addWorkingDays(d, days, holidays) {
  if (days < 0) return subtractWorkingDays(d, Math.abs(days), holidays)

  while (days > 0) {
    d.setDate(d.getDate() + 1)
    if (!isHoliday(d, holidays)) days--
  }

  return d
}

function subtractWorkingDays(d, days, holidays) {
  while (days > 0) {
    d.setDate(d.getDate() - 1)
    if (!isHoliday(d, holidays)) days--
  }

  return d
}

function isHoliday(d, holidays) {
  if (d.getDay() == 0 || d.getDay() == 6) return true

  // if date is present in the holiday list return true;
  var YYYYMMDD = dateUtil.format(d, "YYYY-MM-DD")
  return holidays.indexOf(YYYYMMDD) > -1
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul {
  list-style-type: none;
  padding-left: 0rem;
  font-size: 1.75rem;
}

ul li {
  margin-bottom: 1rem;
}

ul li img {
  height: 2rem;
  position: relative;
  top: 0.3rem;
}

ul li a:hover,
.highlight {
  color: #dc002e;
}

h2 img {
  float: right;
  position: relative;
  bottom: -0.2rem;
  height: 3rem;
}

.panel {
  background: #dc002e;
  color: white;
  padding: 1rem 2rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.panel.account-info {
  background: #2c8732;
  padding-bottom: rem;
}

.panel.account-info p {
  margin-bottom: 1.5rem;
}

.btn-view {
  background: #dc002e;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: 0;
  cursor: pointer;
}

.info-circle {
  float: right;
}

.countdown {
  background: white;
  border-radius: 0.5rem;
  color: #5f6062;
  padding: 1rem 2rem;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.countdown.problem {
  animation: blinkingText 1s infinite;
}

.test {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #fff;
}

.test p {
  padding-bottom: 1rem;
}

.useful {
  padding-left: 2rem;
  padding-bottom: 2rem;
}

@keyframes blinkingText {
  0% {
    color: #dc002e;
  }

  49% {
    color: #dc002e;
  }

  60% {
    color: transparent;
  }

  99% {
    color: transparent;
  }

  100% {
    color: #dc002e;
  }
}

.note {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.note span {
  white-space: nowrap;
}

.note a {
  text-decoration: underline;
}

.table-small {
  background: #f3f3f3;
  border-radius: 1rem;
  padding: 2rem;
}

.table-small table {
  text-align: left;
  width: 100%;
  border-collapse: collapse;
  border: 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ddd;
}

.table-small table td {
  padding-top: 1rem;
}

.table-small table th,
.table-small table tbody tr:last-child td {
  padding-bottom: 1rem;
}

.table-small table th {
  border-bottom: 1px solid #ddd;
}

.ordernote {
  background: #cb4e0b;
  color: white;
  border-radius: 1rem;
  padding: 1rem 2rem;
}
</style>
